import React from 'react';
import {
  Stack,
  Text,
  FontWeights,
  PrimaryButton
} from 'office-ui-fabric-react';
import { AppState } from './RootReducer';
import { Dispatch } from 'redux';
import { OPEN_SIGNIN_DIALOG_COMMAND } from './auth/actions';
import { connect } from 'react-redux';
import signInImage from './images/signin.svg';
import { FormattedMessage } from 'react-intl';
import { translate } from './localization/translate';

import './index.css';

const boldStyle = { root: { fontWeight: FontWeights.semibold } };

interface SigninPageProps {
  onSignIn: () => void;
}

const mapStateToProps = (state: AppState) => ({} as Partial<SigninPageProps>);

const mapDispatchToProps = (dispatch: Dispatch) =>
  ({
    onSignIn: () =>
      dispatch({
        type: OPEN_SIGNIN_DIALOG_COMMAND
      })
  } as Partial<SigninPageProps>);

function SigninPageComponent(props: Partial<SigninPageProps>) {
  const onSignIn = props.onSignIn ?? (() => {});

  return (
    <>
      <Stack
        className="container"
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        tokens={{
          childrenGap: 35
        }}
      >
      <p>1</p>
        <img
          className="splashImage"
          src="https://zajuna.sena.edu.co/img/logos/zajuna-logo.svg"
          alt={translate('signin.logo')}
        />
        <Text variant="xLargePlus" styles={boldStyle} className="signinHeader">
          <FormattedMessage id="signin.header" />
          
        </Text>

        <Text variant="medium" className="uTextCenter signinSubHeader">
          <FormattedMessage id="signin.subheader.lead" />
        </Text>
        <Text variant="medium" className="uTextCenter signinSubHeader">
          <FormattedMessage id="signin.subheader.signin" />
        </Text>

        <PrimaryButton
          className="teamsButton"
          onClick={() => onSignIn()}
          ariaLabel={translate('signin.button.ariaLabel')}
        >
          <FormattedMessage id="signin.button" />
        </PrimaryButton>
      </Stack>
    </>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SigninPageComponent);
